import Cookies from 'js-cookie';
import { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import { Text, Colors } from "../../components/common/design-system";
import Header from "../../components/Header";
import { Panel } from "../../components/Panel";
import { Chart } from "../../components/Chart";
import { allChargePointsApi, allOcpiChargePointsApi } from '../../api/client';
import { Charger } from '../../api/ocpp/api';
import { Box, FormControl, InputLabel, Select as MuiSelect, MenuItem, TextField, Tooltip, Button } from "@mui/material";
import Select from "react-select";
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DatePicker from '@mui/lab/DatePicker';
import { BREAKPOINTS } from "../../components/common/breakpoints";
import { subMonths, getYear, getMonth, subWeeks, subYears, startOfYear } from 'date-fns';
import { SkeletonLoader } from '../../components/SkeletonLoader';
import { InfoOutlined } from '@mui/icons-material';
const StyledStack = styled.div`
  display: flex;
  flex-flow: column;
  width: 100%;
  justify-content: space-between;
  margin: 0 0 0 16px;
  box-sizing: border-box;

  @media(min-width: ${BREAKPOINTS.DESKTOP_MD}px) {
    width: calc(50% - 32px);
    margin-top: 16px;
  }
`;

const StyledHalf = styled.div`
  width: 100%;
  margin: 0 0 0 16px;
  box-sizing: border-box;
  
  @media(min-width: ${BREAKPOINTS.DESKTOP_MD}px) {
    width: 50%;
    margin: 16px 0 0;
  }

`;
interface SessionDataProps {
  time?: string;
  chargerId?: string;
  start?: string;
  end?: string;
}

interface OptionType {
  label: string;
  value: string;
}

interface GroupType {
  label: string | React.ReactNode;
  options: OptionType[];
}

const Overview = () => {
  const [startDate, setStartDate] = useState<string>(subMonths(new Date(), 1).toISOString());
  const [endDate, setEndDate] = useState<string>(new Date().toISOString());
  const [activeChargers, setActiveChargers] = useState<{label: string, value: string}[]>();
  const [timeframe, setTimeframe] = useState("week");
  const [allStats, setAllStats] = useState([]);
  const [allChargers, setAllChargers] = useState<Charger[]>([]);
  const [uniqueAddresses, setUniqueAddresses] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [showRange, setShowRange] = useState(false);
  const [formattedRevenueData, setFormattedRevenueData] = useState([]);
  const [formattedSessionData, setFormattedSessionData] = useState([]);
  const [formattedEnergyData, setFormattedEnergyData] = useState([]);
  const getActiveChargersLength = (data) => {
    const activeChargerIds = activeChargers?.map(ac => parseInt(ac.value));
    
    const statusTotals = data.filter(charger => 
      charger.chargerId && charger.active && activeChargerIds?.length && activeChargerIds.includes(charger.chargerId)
    );

    return statusTotals.length;
  }
  const groupStatusTotals = (data) => {
    const activeChargerIds = activeChargers?.map(ac => parseInt(ac.value));
    const statusTotals = data.filter(charger => 
      charger.chargerId && charger.active && activeChargerIds?.length && activeChargerIds.includes(charger.chargerId)
    ).reduce((totals, charger) => {
      const { status } = charger;
      totals[status] = (totals[status] || 0) + 1;
      return totals;
    }, {});
  
    const transformedData = Object.entries(statusTotals).map(([status, count]) => {
      if (status === "NeverConnected") {
        status = "Never Connected";
      } else if (status === "Preparing") {
        status = "Starting up";
      } else if (status === "SuspendedEV") {
        status = "Charging Suspended"
      }
      return {
        id: status,
        value: count as number,
        label: status
      };
    });
  
    return transformedData;
  };

  const fetchSessionData = useCallback(async ({
    time,
    chargerId,
    start = startDate,
    end = endDate,
  }: SessionDataProps) => {
    try {
      setLoading(true);
      const combinedSessionData = [];
      const accessToken = Cookies.get('authKey');   
      // const { data } = await allChargePointsApi.listAllSessionsGrouped(
      //   start,
      //   end,
      //   (chargerId || `${activeChargers?.map(x => x.value).join(",")}`),
      //   (time || timeframe),
      //   {
      //     withCredentials: true,
      //     headers: {
      //       "accept": "application/json",
      //       "Authorization": `Bearer ${accessToken}`,
      //     }
      //   }
      // ); 
      const data = [];
      if (data.length) combinedSessionData.push(...data);
      const { data: ocpiData } = await allOcpiChargePointsApi.listAllSessions(
        start,
        end,
        (chargerId || `${activeChargers?.map(x => x.value).join(",")}`),
        (time || timeframe),
        {
          withCredentials: true,
          headers: {
            "accept": "application/json",
            "Authorization": `Bearer ${accessToken}`,
          }
        }
      );
      if (ocpiData.length) combinedSessionData.push(...ocpiData);
      const isArrayOfArrays = combinedSessionData.every(Array.isArray);
      let combinedData = [];
      if (isArrayOfArrays) {
        combinedData = combinedSessionData.reduce((acc, arr) => acc.concat(arr), []);
      }  else {
        combinedData = combinedSessionData;
      }
      setAllStats(combinedData);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setAllStats([]);
      setLoading(false);
      return [];
    }
  }, [startDate, endDate, timeframe, activeChargers]);

  const fetchChargerData = useCallback(async () => {
    const combinedChargers = [];
    setLoading(true);
    try {
      const accessToken = Cookies.get('authKey');  
      const { data: chargerData } = await allChargePointsApi.listAllChargers({ 
        withCredentials: true,
        headers: {
          "Authorization": `Bearer ${accessToken}`,
        },
      });
      if (chargerData.length) combinedChargers.push(...chargerData);
      const { data: ocpiChargers } = await allOcpiChargePointsApi.listAllEvseChargers({ 
        withCredentials: true,
        headers: {
          "Authorization": `Bearer ${accessToken}`,
        },
      });
      if (ocpiChargers.length) combinedChargers.push(...ocpiChargers);
     
      const groupedData = combinedChargers.reduce((groups, item) => {
        const addressLine = `${item.address_line1}, ${item.city}, ${item.state} ${item.zip}`;
        groups[addressLine] = groups[addressLine] || [];
        groups[addressLine].push(item);
        return groups;
      }, {});
      setAllChargers(combinedChargers); 
      setUniqueAddresses(Object.keys(groupedData));
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
      return [];
    }
  }, []);
  
  useEffect(() => {
    if (allStats.length > 0) {
      setFormattedRevenueData(formatData("earnings_cents"));
      setFormattedSessionData(formatData("session_count"));
      setFormattedEnergyData(formatData("energy_kwh"));
    }
  }, [allStats]);
  
  useEffect(() => {
    fetchChargerData();
  }, []);

  useEffect(() => {
    switch (timeframe) {
      case "week":
        setShowRange(false);
        const weekAgo = subWeeks(new Date(), 1);
        setStartDate(weekAgo.toISOString());
        setEndDate(new Date().toISOString());
        return;
      case "month":
        setShowRange(false);
        const monthAgo = subMonths(new Date(), 1);
        setStartDate(monthAgo.toISOString());
        setEndDate(new Date().toISOString());
        return;
      case "year":
        setShowRange(false);
        const yearAgo = subYears(new Date(), 1);
        setStartDate(yearAgo.toISOString());
        setEndDate(new Date().toISOString());
        return;
      case "ytd":
        setShowRange(false);
        const ytd = startOfYear(new Date()).toISOString();
        setStartDate(ytd);
        setEndDate(new Date().toISOString());
        return;
      case "all":
        setShowRange(false);
        setStartDate(new Date("01/01/2022").toISOString());
        setEndDate(new Date().toISOString());
        return;
      case "custom":
        setShowRange(true);
        return;
      default:
        const weekAgo2 = subWeeks(new Date(), 1);
        setStartDate(weekAgo2.toISOString());
        setEndDate(new Date().toISOString());
    }
  }, [timeframe]);
  

  const formattedAmount = (amount) => {
    return amount.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD'
    })
  };

  const renderAllRevenue = () => {
     const total = allStats.reduce((total, item) => total + Number(item.earnings_cents), 0);
    return <Text bold>{formattedAmount(total / 100)}</Text>;
  };

  const renderAllEnergy = () => {
    const total = allStats.reduce((total, item) => total + Number(item.energy_kwh), 0);
    return <div style={{ display: "flex"}}>
       <Text bold>{Math.round(total)}</Text><Text ml={1} fontSize="18px" bold>kWh</Text>
    </div>;
  };

  const renderAllSession = () => {
    const total = allStats.reduce((total, item) => total + (item.session_count || 1), 0);
    return <Text bold>{total}</Text>;
  };

  const handleFilterChange = (name: string, value: OptionType[] | any) => {
    switch (name) {
      case "timeframe":
        setTimeframe(value);
        break;
      case "startDate":
        setStartDate(value);
        break;
      case "endDate":
        setEndDate(value);
        break;
      case "activeCharger":
        setActiveChargers(value);
    }
  };

  const handleFetchSessions = () => {
    fetchSessionData({
      time: "year",
      chargerId: activeChargers?.length ? `${activeChargers.map(x => x.value).join(",")}` : null,
      start: startDate,
      end: endDate,
    });
  };

  const getMonthName = (value, year) => {
    const months = [
      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
      'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];
    return `${months[value - 1]} ${year}`;
  };

  const generateMonthRange = (startDate, endDate) => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    const months = [];
    while (start <= end) {
      months.push({
        id: getMonthName(start.getMonth() + 1, start.getFullYear()),
        value: 0,
      });
      start.setMonth(start.getMonth() + 1);
    }
    return months;
  };

  const formatData = (key) => {
    // Generate the range of months between start and end date
    const combinedData = generateMonthRange(startDate, endDate);
  
    // Create a map to track the values for each month
    const dataMap = new Map(combinedData.map(item => [item.id, item]));
  
    // Process the actual data
    allStats.forEach(stat => {
      const monthName = getMonthName(
        getMonth(new Date(stat.start_transaction_timestamp)) + 1,
        getYear(new Date(stat.start_transaction_timestamp))
      );
      const value = key === "earnings_cents" 
        ? Number(stat[key]) / 100 
        : key === "energy_kwh" 
          ? Number(stat[key]) 
          : key === "session_count" && !stat.session_count 
            ? 1 
            : Number(stat);
  
      if (dataMap.has(monthName)) {
        dataMap.get(monthName).value += value;
      } else {
        dataMap.set(monthName, { id: monthName, value });
      }
    });
  
    // Convert the map back to an array and round the values if necessary
    const combinedArray = Array.from(dataMap.values()).map(item => {
      if (key === "earnings_cents" || key === "energy_kwh") {
        item.value = Number(item.value.toFixed(2));
      }
      return item;
    });
  
    return combinedArray;
  };

  const createGroup = (
    groupName: string,
    options: OptionType[],
    setValue
  ): GroupType => {
    return {
      label: (
        <div
          style={{
            cursor: "pointer",
            fontWeight: "bold",
            color: "black",
          }}
          onClick={() => {
            setValue("activeCharger", options)
          }
            
          }
        >
          {groupName}
        </div>
      ),
      options: options,
    };
  };

  const options: GroupType[] = uniqueAddresses.map(address => {
    const chargersPerAddress = allChargers.filter(x => `${x.address_line1}, ${x.city}, ${x.state} ${x.zip}` === address);
    const menuItems: OptionType[] = chargersPerAddress.map(charger => ({ label: charger.chargePointIdentity ? `${charger.chargePointIdentity}` : `${(charger.stickerId || charger.name || 'Unknown')}-${charger.chargerId} ${charger.stickerId ? `(${charger.stickerId})` : ''} ${!charger.active ? '(inactive)' : ''}`, value: String(charger.chargerId) }));
    const option = createGroup(address, menuItems, handleFilterChange);
    return option;
  });

  return (
    <div style={{ width: "100%" }}>
      <Header title="Overview" />
      <div style={{ marginBottom: 10, alignItems: "flex-end", display: "flex", marginRight: 5 }}>
        <div style={{display: 'flex', marginLeft: 16, marginTop: 16, flexFlow: 'column', width: '50%', maxWidth: 650}}>
          <div>
            <Tooltip title="The number in parentheses is the sticker ID displayed on front of charger.">
              <div style={{ display: 'inline-flex', marginRight: 15}}>
                <Text>Chargers: </Text>
                <InfoOutlined />
              </div>
            </Tooltip>
          </div>
          <FormControl>
            <Select
              isDisabled={loading}
              styles={{
                control: (baseStyles) => ({
                  ...baseStyles,
                  background: 'transparent',
                  padding: 3,
                }),
                placeholder: (baseStyles) => ({
                  ...baseStyles,
                  fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
                  color: 'black',
                  fontWeight: 400
                }),
                multiValue: (baseStyles) => ({
                  ...baseStyles,
                  minWidth: null,
                }),
                groupHeading: (baseStyles) => ({
                  ...baseStyles,
                  padding: '8px',
                  "&:hover": {
                    background: "#ddebff",
                  }
                }),
                valueContainer: (baseStyles) => ({
                  ...baseStyles,
                  flexFlow: 'row',
                  overflow: 'auto',
                })
              }}
              onChange={(option) => {
                handleFilterChange("activeCharger", option)
              }}
              closeMenuOnSelect={false}
              isMulti
              options={options as any}
              value={activeChargers}
              placeholder="Select"
            />
          </FormControl>
        </div>
        <Box sx={{ minWidth: 120, ml: 2 }}>
          <FormControl fullWidth style={{height: 44}}>
            <InputLabel id="demo-simple-select-label">Date Range</InputLabel>
            <MuiSelect style={{height: 44}} disabled={loading} size='small' labelId="demo-simple-select-label" label="Breakdown" displayEmpty value={timeframe} onChange={(e) => handleFilterChange("timeframe", e.target.value as string)}>
              <MenuItem value="week">Last 7 Days</MenuItem>
              <MenuItem value="month">Last 30 Days</MenuItem>
              <MenuItem value="ytd">Year-to-date</MenuItem>
              <MenuItem value="all">All time</MenuItem>
              <MenuItem value="custom">Custom</MenuItem>
            </MuiSelect>
          </FormControl>  
        </Box>
        {showRange && (
          <Box>
            <FormControl sx={{ mt: 3, ml:1,  width: 150, p: 0 }}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Start Date"
                  value={startDate}
                  disabled={loading}
                  onChange={(newValue) => {
                    const date = new Date(newValue).toISOString();
                    handleFilterChange("startDate", date);
                  }}
                  renderInput={(params) => <TextField style={{height: 27}} size="small" {...params} />}
                />
              </LocalizationProvider>
            </FormControl>
            <FormControl sx={{ mt: 3, ml:1,  width: 150, p: 0, height: 44}}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="End Date"
                  value={endDate}
                  disabled={loading}
                  onChange={(newValue) => {
                    const date = new Date(newValue).toISOString();
                    handleFilterChange("endDate", date);
                  }}
                  renderInput={(params) => <TextField style={{height: 27}} inputProps={{style: { height: 27 }}} size="small" {...params} />}
                />
              </LocalizationProvider>
            </FormControl>
          </Box>
        )}
        <Button disabled={loading || !activeChargers?.length} onClick={handleFetchSessions} style={{ marginBottom: 5, marginLeft: 12}}>Submit</Button>
      </div>
        
      <div style={{ width: "100%", display: "flex", flexFlow: "row wrap", justifyContent: "space-between" }}>
          <StyledStack>
            <Panel smallPadding loading={loading}>
              <Text.Body>Fees collected</Text.Body>
              {loading ? <SkeletonLoader width="100px" /> : <Text.Heading size="xs" color={Colors.blue}>{renderAllRevenue()}</Text.Heading>}
            </Panel>
            <Panel smallPadding loading={loading}>
              <Text.Body>Energy used</Text.Body>
              {loading ? <SkeletonLoader  width="100px" /> : <Text.Heading size="xs" color={Colors.blue}>{renderAllEnergy()}</Text.Heading>}
            </Panel>
            <Panel smallPadding loading={loading}>
              <Text.Body>Sessions</Text.Body>
              {loading ? <SkeletonLoader width="75px"/> : <Text.Heading size="xs" color={Colors.blue}>{renderAllSession()}</Text.Heading>}
            </Panel>
          </StyledStack>
          <StyledHalf>
          
            <Panel loading={loading} noData={Boolean(!allStats.length)} headerText="Breakdown of charger status" height={250} tooltip="This is the current status of your charger(s)">
              <div style={{ height: 200, width: "100%" }}>
              {loading ? <SkeletonLoader height="200px" /> : <Chart type="pie" data={activeChargers?.length ? groupStatusTotals(allChargers) : null} total={getActiveChargersLength(allChargers)} />}
              </div>
            </Panel>
          </StyledHalf>
      </div>
      <div style={{ marginLeft: 16}}>
          <Panel loading={loading} noData={Boolean(!allStats.length)} height={250} headerText="Revenue (USD)">
            <div style={{ height: 250, width: "100%" }}>
            {loading ? <SkeletonLoader height="250px"/> : <Chart type="bar" data={formattedRevenueData} indexBy="id" />}
            </div>
          </Panel>
      </div>
      <div style={{ marginLeft: 16}}>
      <Panel loading={loading} noData={Boolean(!allStats.length)} width="full" headerText="Charging Sessions (Count)">
        <div style={{ height: 250, width: "100%" }}>
            {loading ? <SkeletonLoader height="250px" /> : <Chart type="bar" data={formattedSessionData} indexBy="id" />}
        </div>
      </Panel>
      </div>
      <div style={{ marginLeft: 16}}>
      <Panel loading={loading} noData={Boolean(!allStats.length)} headerText="Energy (kWh)" width="full">
        <div style={{ height: 250, width: "100%" }}>
            {loading ? <SkeletonLoader height="250px" /> : <Chart type="bar" data={formattedEnergyData} indexBy="id" />}
        </div>
      </Panel>
      </div>
    </div>
  );
};

export default Overview;
