
import * as React from 'react';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import FormControlLabel from '@mui/material/FormControlLabel';
import styled from 'styled-components';
import { Data, TableData } from './TableData';
import { EnhancedTableHead, getComparator, Order } from './TableHead';
import OrangeSwitch from '../../../components/OrangeSwitch';
import { FETCH_INTERVAL } from '../../../constants/index';
import { RemoteStartOrStopTransactionResponseStatus, ChargerStatuses } from '../../../api/ocpp';
import { RemoteStartOrStopTransactionResponseStatus as OcpiRemoteStartOrStopTransactionResponseStatus } from '../../../api/ocpi';
import { rootUrl } from '../../../App';
import { Colors } from '../../../components/common/design-system';

import { allOcpiChargePointsApi, specificChargePointApi } from '../../../api/client';
import Cookies from 'js-cookie';
import { SkeletonLoader } from '../../../components/SkeletonLoader';


const StyledWrapper = styled.div`
  padding: 30px;
  width: calc(100% - 30px);  
`;

const StyledLink = styled.a`
  color: ${Colors.blue};
  text-decoration: none;
`;

interface ToggleChargerProps {
  event?: React.ChangeEvent<HTMLInputElement>,
  id: string,
  toggleState: boolean,
  idx: number,
  isOcpi?: boolean,
};

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array?.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function TableView() {
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<keyof Data>('name');
  const [selected, setSelected] = React.useState<readonly string[]>([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [isLoading, setIsLoading] = React.useState<string[]>([]);
  const [rows, setRows] = React.useState<Data[]>([]);
  const [searchText, setSearchText] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const pollers = [];
  const accessToken = Cookies.get('authKey');  

  React.useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const rowData = await TableData();
      if (rowData) {
        setRows(rowData);
        const intervalIdx = rowData.find(x => x.status === ChargerStatuses.Preparing);
        fetchChargerDataInterval(intervalIdx?.name);
      }
      setLoading(false);
    }
    if (!rows.length) {
      fetchData();
    }
  }, []);

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Data) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDense(event.target.checked);
  };

  const fetchChargerDataInterval = (id: string) => {
    const arrayIndex = rows.findIndex(x => x.name === id);
    pollers.push({
      id,
      interval: () => setInterval(async () => {
        try {
          const newRows: Data[] = await TableData();
          setRows(newRows);
          if (newRows && newRows[arrayIndex]) {
            if (
              newRows[arrayIndex]?.status === ChargerStatuses.Charging || 
              newRows[arrayIndex]?.status === ChargerStatuses.Available || 
              newRows[arrayIndex]?.status === ChargerStatuses.Faulted ||
              newRows[arrayIndex]?.status === ChargerStatuses.SuspendedEv ||
              newRows[arrayIndex]?.status === ChargerStatuses.SuspendedEvse
            ) {
              if (isLoading) setIsLoading([...isLoading.splice(arrayIndex, 1)]);
              clearInterval(pollers.find(x => x.name === id).interval);
            }
          }
        } catch (err) {
          if (isLoading) setIsLoading([...isLoading.splice(arrayIndex, 1)]);
          console.log("Fetch all chargers error: ", err);
        }
      }, FETCH_INTERVAL)
    });
    pollers.find(x => x.id === id).interval();
  };

  const toggleCharger = async ({ id, toggleState, idx, isOcpi}: ToggleChargerProps) => {
    setIsLoading([...isLoading, id]);
    try {
      if (toggleState) {
       if (isOcpi) {
        await allOcpiChargePointsApi.evseRemoteStop(id, {
          withCredentials: true,
          headers: {
            "Authorization": `Bearer ${accessToken}`,
          },
        });
       } else {
        await specificChargePointApi.chargersV1ChargePointIdentityRemoteStopTransactionPost(id, {
          withCredentials: true,
        });
       }
         
      } else {
        if (isOcpi) {
          const { data } = await allOcpiChargePointsApi.evseRemoteStart(id, {
            withCredentials: true,
            headers: {
              "Authorization": `Bearer ${accessToken}`,
            },
          });
          if (data.data.result === OcpiRemoteStartOrStopTransactionResponseStatus.Accepted) {
            fetchChargerDataInterval(id);
            setRows((prev) => {
              return prev.map(row => row.evse_id === id ? { ...row, toggle: true } : row);
            });
          }

        } else {
          const { data } =
          await specificChargePointApi.chargersV1ChargePointIdentityRemoteStartTransactionPost(
            id,
            null,
            { withCredentials: true }
          );
          
         if (data.acknowledgment?.status === RemoteStartOrStopTransactionResponseStatus.Accepted) {
          fetchChargerDataInterval(id);
         }
        }
      }
    } catch (err) {
      console.log(err);
      if (isLoading) setIsLoading([...isLoading.splice(idx, 1)]);;
    }
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;
  // Filter rows based on the search text
  const filteredRows = rows.filter(row => {
    return row.name.toLowerCase().includes(searchText.toLowerCase()) ||
           row.chargepoint.toLowerCase().includes(searchText.toLowerCase()) ||
           row.stickerId.toLowerCase().includes(searchText.toLowerCase()) ||
           row.status.toLowerCase().includes(searchText.toLowerCase()) ||
           row.evse_id?.toLowerCase().includes(searchText.toLowerCase());
  });

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows?.length) : 0;

  const renderLoader = () =>  <TableRow
  role="checkbox"
  tabIndex={-1}
>
  <TableCell component="th" scope="row">
    <SkeletonLoader />
  </TableCell>
  <TableCell align="right" scope="row">
    <SkeletonLoader />
  </TableCell>
  <TableCell align="right"><SkeletonLoader /></TableCell>
  <TableCell align="right"><SkeletonLoader /></TableCell>
  <TableCell align="right"><SkeletonLoader /></TableCell>
  <TableCell align="right"><SkeletonLoader /></TableCell>
  <TableCell align="right"><SkeletonLoader /></TableCell>
  <TableCell align="right"><SkeletonLoader /></TableCell>
  <TableCell align="right"><SkeletonLoader /></TableCell>
</TableRow>

  return (
    <StyledWrapper>
      <Box sx={{ width: '100%' }}>
        <Paper sx={{ width: '100%', mb: 2 }}>
          {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
          <div style={{ padding: 24, marginTop: 24 }}>
            <input
              type="text"
              placeholder="Search..."
              value={searchText}
              onChange={handleSearchChange}
              style={{ marginBottom: '10px', padding: '8px', width: 'calc(100% - 16px)' }}
            />
          </div>
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={dense ? 'small' : 'medium'}
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows?.length || 0}
              />
              
              <TableBody>
              {loading ? (
                <>
                  {renderLoader()}
                  {renderLoader()}
                  {renderLoader()}
                  {renderLoader()}
                  {renderLoader()}
                </>
                ) : (
                  <>
                  {stableSort(filteredRows, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = isSelected(row.name);
                    const labelId = `enhanced-table-checkbox-${index}`;
                    const isDisabled = Boolean(isLoading.filter(x => x === row.name).length);
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.id}
                        selected={isItemSelected}
                      >
                        <TableCell component="th" id={labelId} scope="row">
                          <StyledLink
                            className="Table__Link"
                            href={`${rootUrl()}/public/${row.id}`}
                            target="_blank"
                          >
                            {row.chargepoint}
                          </StyledLink>
                        </TableCell>
                        <TableCell align="left" scope="row">
                          {row.name}
                        </TableCell>
                        <TableCell align="left" scope="row">
                          {row.stickerId}
                        </TableCell>
                        <TableCell align="left" scope="row">
                          {row.status}
                        </TableCell>
                        <TableCell align="left">
                          <FormControlLabel
                            control={
                              <OrangeSwitch
                                checked={Boolean(row.toggle)}
                                onChange={() => toggleCharger({ id: Boolean(row.evse_id) ? row.evse_id : String(row.chargepoint), toggleState: Boolean(row.toggle), idx: index, isOcpi: Boolean(row.evse_id) })}
                                inputProps={{ 'aria-label': 'controlled' }}
                                disabled={isDisabled || row.status === ChargerStatuses.Preparing}
                              />
                            }
                            label="Off / On"
                          />
                        </TableCell>
                        <TableCell align="left">

                          <Link to={`/reports/sessions?chargerIds=${row.id}`}>
                            View Details
                          </Link>
                        </TableCell>
                      </TableRow>
                    );
                  })}</>
                )}
                {/* if you don't need to support IE11, you can replace the `stableSort` call with:
              rows.slice().sort(getComparator(order, orderBy)) */}
                
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (dense ? 33 : 53) * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={rows?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
        <FormControlLabel
          control={
            <OrangeSwitch
              checked={dense}
              onChange={handleChangeDense}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          }
          label="Dense padding"
        />
      </Box>
    </StyledWrapper>
  );
}

export default TableView;
